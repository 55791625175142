// Migrated
<template lang="pug">
div(ref="container")
  slot(v-bind="{ inView }")
</template>

<script>
export default defineNuxtComponent({
  name: 'InView',

  props: {
    scrollTarget: {
      type: [htmlTypes.HTMLElement, ...(import.meta.browser ? [Window] : [])],
      default: import.meta.browser ? () => window : null,
    },

    removeScrollListenerOnceInView: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['trigger'],

  data () {
    return {
      inView: false,
    }
  },

  watch: {
    scrollTarget (newTarget, prevTarget) {
      prevTarget?.removeEventListener('scroll', this.onScrollListener)
      newTarget?.addEventListener('scroll', this.onScrollListener)
    },
  },

  mounted () {
    this.onScrollListener()
    this.scrollTarget?.addEventListener('scroll', this.onScrollListener)
    this.scrollInterval = setInterval(this.onScrollListener, 1000)
  },

  updated () {
    this.onScrollListener()
  },

  beforeUnmount () {
    this.scrollTarget?.removeEventListener('scroll', this.onScrollListener)
    clearInterval(this.scrollInterval)
  },

  methods: {
    getScrollCoordinates () {
      const scrollTargetTop = this.scrollTarget instanceof Window ? 0 : this.scrollTarget?.getBoundingClientRect()?.top || 0

      return {
        top: (this.$refs.container.getBoundingClientRect()?.top - scrollTargetTop) || 0,
        innerHeight: this.scrollTarget?.offsetHeight ?? this.scrollTarget?.innerHeight,
        offset: 150,
      }
    },

    onScrollListener () {
      if (!this.$refs.container || !import.meta.browser || !this.scrollTarget) {
        return
      }

      const { top, innerHeight, offset } = this.getScrollCoordinates()

      if (top < innerHeight + offset) {
        this.$emit('trigger')
        this.inView = true

        if (this.$props.removeScrollListenerOnceInView) {
          this.scrollTarget?.removeEventListener('scroll', this.onScrollListener)
          clearInterval(this.scrollInterval)
        }
      }
    },
  },
})
</script>
